const SelelectedItemComponent = (props) => {
    const {listArray, onClick} = props
    return ( 
    <div className="selected_section">
        <div className="right_section">
          {
            listArray.map((item, index)=>(   
              <div className="checkbox_individual_wraper" id={index}> 
                <ul className="ul_right">
                <h3> {item[0].heading}</h3>
                {
                  item.map((data, key)=>(
                     <li id={key}> {data.value}   <span className="remove_item" onClick={(id, heading)=>onClick(data.id, data.heading)}> <i className="fa fa-remove"></i> </span></li>
                     ))
                    }
                    </ul>
              </div>
            ))
          }
           {
            !listArray.length &&
              <div className="no-item-selected">
                  There is no item selected from checkbox menu
              </div>
          }
        </div>
      </div>
    )
}
export default SelelectedItemComponent