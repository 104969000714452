import React from 'react';

const CheckboxComponent = (props) => {
  const {checked, labelText, onChange, id, headingCheckBox} = props
  return (
    <div class="checkbox">
      <label><input type="checkbox" id={id} value={labelText} checked={checked} onChange={(e,heading)=>onChange(e,headingCheckBox)}/>{labelText}</label>
    </div>
  )
}


export default CheckboxComponent

