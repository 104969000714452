import { act } from 'react-dom/test-utils';
import * as type from '../types';

const initialState = {
    search: [],
    loading: false,
    error: null,
  }
  
  export default function userReducer(state = initialState, action) {
    console.log('action ----reducer',action.payload)
    switch (action.type) {
      case type.GET_USERS_REQUESTED:
        return {
          ...state,
          loading: true,
        }
      case type.GET_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          search: [...state.search, action.payload]
        }
      case type.GET_USERS_FAILED:
        return {
          ...state,
          loading: false,
          error: action.message,
        }
      default:
        return state
    }
  }