import React from 'react'
import { connect } from 'react-redux'
import CheckboxComponent from './components/checkboxComponent'
import Header from './components/header'
import Logo from './components/logo'
import SelelectedItemComponent from './components/selelectedItemComponent'

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isChecked: false,
      checkBoxList: [
        {checkboxHeading: "Portugal", 
          checkListPortugal: [
              {id:1 , value:'Asaya Jayavant', isChecked: false},
              {id:2 , value:'Luvleen Lawrence', isChecked: false},
              {id:3 , value:'Rey Mibourne', isChecked: false},
              {id:4 , value:'Cayla Brister', isChecked: false}
          ] 
        },
        {checkboxHeading: "Nicargua", 
         checkListPortugal: [
            {id:5 , value:'Deveedas Nandi', isChecked: false},
            {id:6 , value:'Obasey Chidy', isChecked: false},
            {id:7 , value:'Xenie Dalezelova', isChecked: false},
            {id:8 , value:'Ezequiel Dengra', isChecked: false
          }
         ] 
       },
       {checkboxHeading: "Marshall Island", 
        checkListPortugal: [
          {
            id:9 , value:'Aaron Almaraz', isChecked: false},
            {id:10 , value:'Jelena Denisova', isChecked: false},
            {id:11 , value:'Marshel Almaraz', isChecked: false},
            {id:12 , value:'Denisova Aaron', isChecked: false
          }
        ] 
       }
      ],
      selectedCheckBox: [],
      listArray: []
    }
  }
  handleRemoveItem = (id, heading) => {
    console.log('id', id, heading)
    let checkBoxList = this.state.checkBoxList
    checkBoxList.forEach(item => {
      item.checkListPortugal.forEach((data)=>{
        if(data.id === id){
          // console.log(data)
          data.isChecked = false
          let obj = {...data, heading}
          if(data.isChecked){
            this.setState({selectedCheckBox: [...this.state.selectedCheckBox, obj]})
          }else{
            this.state.selectedCheckBox.forEach((item, index)=>{
              if(item.id === data.id){
                this.state.selectedCheckBox.splice(index,1)
              }
            })
          }
        }
      })
    }) 
    this.setState({checkBoxList: checkBoxList},()=>{
      let updatedArr = [...Object.values(this.groupBy(this.state.selectedCheckBox, 'heading'))]
      this.setState({listArray: updatedArr})
    })
  }
  groupBy = (arr, property) => {
    return arr.reduce(function(memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
    }, {});
  }
  handleCheckBox = (event, heading) => {
    let checkBoxList = this.state.checkBoxList
    checkBoxList.forEach(item => {
      item.checkListPortugal.forEach((data)=>{
        if(data.value === event.target.value){
          data.isChecked = event.target.checked
          let obj = {...data, heading}
          if(data.isChecked){
            this.setState({selectedCheckBox: [...this.state.selectedCheckBox, obj]})
          }else{
            this.state.selectedCheckBox.forEach((item, index)=>{
              if(item.id === data.id){
                this.state.selectedCheckBox.splice(index,1)
              }
            })
          }
        }
      })
    }) 
    this.setState({checkBoxList: checkBoxList},()=>{
      let updatedArr = [...Object.values(this.groupBy(this.state.selectedCheckBox, 'heading'))]
      this.setState({listArray: updatedArr})
    })
  }
  render(){
    const { checkBoxList, listArray } = this.state
    return(
      <div className="main_conatiner">
        <div className="main_header">
          <Logo />
          <Header/>
        </div>
        <div className="mid_main_wrap">
          <div className="mid-section">
              <div className="check_box_section">
                  {
                    checkBoxList.map((item, index)=>(
                    <div className="checkbox_individual_wraper">
                      <h3>{item.checkboxHeading}</h3>
                      {
                        item.checkListPortugal.map((data, id)=>(
                          <CheckboxComponent 
                            checked = {data.isChecked}
                            labelText = {data.value}
                            id={data.id}
                            onChange = {this.handleCheckBox}
                            headingCheckBox = {item.checkboxHeading}
                          />
                        ))
                      }
                      </div>
                    ))
                  }
              </div>
            <SelelectedItemComponent listArray={listArray} onClick={(id, heading)=>this.handleRemoveItem(id, heading)}/>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({userReducer}) => ({
  userReducer
})


export default connect(mapStateToProps)(App)