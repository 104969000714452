const Header = () => (
 <div className="right_header">
    <ul className="header_ul">
      <li>
        Home
      </li>
      <li>
        My Portfolio
      </li>
      <li>
        Clients
      </li>
      <li>
        Get in Touch
      </li>
    </ul>
  </div>
)

export default Header